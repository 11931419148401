.taggedTimelineContainer {
  --track-width: calc(100% - 20px);
  --track-height: 8px;
  --track-cursor: pointer;
  --track-border-radius: 4px;
  --track-opacity: 0.75;

  --thumb-left-background: #f1f1f1;
  --thumb-right-background: rgb(255, 255, 255, 0.25);
  --thumb-cursor: grab;

  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 30px;
  touch-action: none;
  margin-bottom: 10px;
}

/* stolen from https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/  */

input.taggedTimelineInput {
  -webkit-appearance: none;
  top: 25px;
  width: var(--track-width);
  overflow: hidden;
  background-color: transparent;
}
input.taggedTimelineInput:focus {
  outline: none;
}
input.taggedTimelineInput::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  height: var(--track-height);
  cursor: var(--track-cursor);
  background: var(--thumb-right-background);
  /* TODO: make the box-shadow progres hack below support border-radius */
  /* border-radius: var(--track-border-radius); */
}
input.taggedTimelineInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: var(--track-height);
  width: 1px;
  cursor: var(--thumb-cursor);
  background: var(--thumb-left-background);
  /* the below hack is because the width of the timeline is calc'ed */
  box-shadow: -10000px 0 0 10000px var(--thumb-left-background);
}

input.taggedTimelineInput::-moz-range-progress {
  width: 100%;
  height: var(--track-height);
  cursor: var(--track-cursor);
  background: var(--thumb-left-background);
  border-radius: var(--track-border-radius);
}

input.taggedTimelineInput::-moz-range-track {
  width: 100%;
  height: var(--track-height);
  cursor: var(--track-cursor);
  background: var(--thumb-right-background);
  border-radius: var(--track-border-radius);
}
input.taggedTimelineInput::-moz-range-thumb {
  height: var(--track-height);
  width: 1px;
  background: var(--thumb-left-background);
  cursor: var(--thumb-cursor);
}
input.taggedTimelineInput::-ms-track {
  width: 100%;
  height: var(--track-height);
  cursor: var(--track-cursor);
  background: transparent;
  color: transparent;
}
input.taggedTimelineInput::-ms-fill-lower {
  background: var(--thumb-left-background);
  border-radius: var(--track-border-radius);
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input.taggedTimelineInput::-ms-fill-upper {
  background: var(--thumb-right-background);
}
input.taggedTimelineInput::-ms-thumb {
  height:  var(--track-height);
  width: 1px;
  cursor: var(--thumb-cursor);
}
