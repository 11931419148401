.tag {
  /*position: relative;*/
  /*padding: 10px;*/
  /*background-color: #fff;*/
  /*width: 20px;*/
  /*border-radius: 20px;*/
  /*!*text-align: center;*!*/
  /*!*margin: 0 auto;*!*/
  /*align-items: center;*/
  /*pointer-events: auto;*/

  position: relative;
  padding: 10px;
  background-color: #fff;
  width: 20px;
  border-radius: 20px;
  font-size: 0.8rem;
  cursor: pointer;
  pointer-events: auto;
}

.tag:hover {
  /*fill: #f00;*/
  /*background-color: #dea9a9;*/
}

.tag:hover text {
  fill: #fff;
}

/*.tag .tagBack {*/
/*    fill: #fff;*/
/*    stroke: #666;*/
/*    stroke-width: .5;*/
/*}*/

.tagBack {
  fill: #ddd;
  stroke: #666;
  stroke-width: 0.5;
}

.tagBackHighlighted {
  fill: #666;
  stroke: #ddd;
  stroke-width: 0.5;
}

.tag:hover .tagBack {
  fill: #666;
  stroke: #fff;
  stroke-width: 1;
}

.tagAnchor {
  fill: #aaa;
  stroke: none;
}

.tagAnchorLine {
  stroke: #fff;
  stroke-width: 0.5;
  stroke-linecap: round;
}

.text {
  fill: #333;
  font-size: 0.8rem;
  text-anchor: middle;
  /*font-weight: 800;*/
}

.textHighlighted {
  fill: #fff;
  font-size: 0.8rem;
  text-anchor: middle;
  font-weight: 800;
}

/*.text:hover {*/
/*    !*color: #f60;*!*/
/*    color: #fff;*/
/*}*/
