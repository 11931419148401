.container {
  display: flex;
  justify-content: space-between;
  margin-right: 12px;
  margin-left: 12px;
}

button.playbackButton {
  border: 0;
  background: none;
  cursor: pointer;
  pointer-events: all;
  color: #f1f1f1;
  opacity: 0.4;
}

button.playbackButton:hover {
  opacity: 1;
}

.playbackControls {
  display: flex;
}
