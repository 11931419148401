.grid {
  position: relative;
  height: 100%;

  display: grid;

  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) 110px;

  text-align: center;
  /*padding: 1px;*/
}

.playViewStacked {
  grid-column: 1/2;
  grid-row: 1/2;

  /*box-shadow: 0 0 10px 2px #000a;*/
}

.playViewHud {
  grid-column: 1/2;
  grid-row: 1/3;

  /*box-shadow: 0 0 10px 2px #000a;*/
}

.playTimeStacked {
  grid-row: 2/3;
  grid-column: 1/2;

  /*background-color: #0002;*/
  /*box-shadow: 0 0 10px #0006;*/

  padding: 1px 0;
  /*text-align: center;*/

  z-index: 100;
}

.playTimeHud {
  grid-row: 2/3;
  grid-column: 1/2;

  background-color: #0002;
  /*box-shadow: 0 0 10px #0006;*/

  padding: 1px 0;
  /*text-align: center;*/

  z-index: 100;
}

/*.fsButtonDiv {*/
/*    position: relative;*/
/*    grid-column: 1/2;*/
/*    grid-row: 1/2;*/
/*    z-index: 100;*/
/*    pointer-events: none;*/
/*}*/

.fsButton {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 3px;
  /*top: 3px;*/
  bottom: 5px;
  border-radius: 0;
  border-width: 0;
  background-color: #fff;
  pointer-events: auto;

  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 10;

  outline: none;
}

.fsIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  /*height: 50%;*/
  transform: translate(-50%, -50%);

  width: 22px;
  height: 22px;
}
