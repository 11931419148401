.figureCaption {
  /*padding-top: 10px;*/
  padding-top: 5px;
  /*transform: translate3d(200%, 0, 0);*/
  transform: translate3d(180%, -100%, 0);
  text-align: right;
  user-select: none;
  pointer-events: none;
}

.figureCaptionContents {
  position: relative;
  /*right: 50px;*/
  right: 80px;
  font-family: monospace;
  color: #333;
  background-color: #fff8;
  padding: 5px;
  min-width: 150px;
}

.figureCaption::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  /*width: 190px;*/
  width: 160px;
  /*transform: translate3d(-120px, 0, 0);*/
  transform: translate3d(-140px, 0, 0);
  height: 1px;
  /*background: white;*/
  background-color: #000000;
}

/*.figureCaption {*/
/*    !*padding-top: 10px;*!*/
/*    padding-top: 5px;*/
/*    !*transform: translate3d(200%, 0, 0);*!*/
/*    !*transform: translate3d(160%, -30%, 0);*!*/
/*    transform: translate3d(160%, -250%, 0);*/
/*    text-align: left;*/
/*    user-select: none;*/
/*    pointer-events: none;*/
/*}*/

/*.figureCaptionContents {*/
/*    position: relative;*/
/*    !*right: 50px;*!*/
/*    !*right: 80px;*!*/
/*    left: -130px;*/
/*    top: -80px;*/
/*    font-family: monospace;*/
/*    color: #333;*/
/*}*/

/*.figureCaption::after {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    content: "";*/

/*    height: 120px;*/
/*    width: 1px;*/
/*    !*transform: translate3d(-120px, 0, 0);*!*/
/*    transform: translate3d(-140px, -80px, 0);*/

/*    !*background: white;*!*/
/*    background-color: #000000;*/
/*}*/
